import React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/layout2"
const SecondPage = () => (
  <Layout>

<header> </header>
<nav class="a">
    <ul>

      <li>
        <Link to="/about/">Our Team</Link>
      </li>
      <li>
      <a target="_blank" href="https://dl.acm.org/doi/pdf/10.1145/3351095.3372859">
      Paper
      </a>
      </li>
      <li><Link to="/studyguide">Study Guide</Link>
      </li>
      <li><a target="_blank" href="https://docs.google.com/document/d/e/2PACX-1vTOjmDqOZyi9XxC5Hiu9uur82WEUk_IGDcWLyBgj4IibAf-WA5QPaLD1qd7kwihCwKvfHaLqiyArTxI/pub">Development</a></li>
      <li><a target="_blank" href="http://rkarkarka.pythonanywhere.com/">Demo</a></li>
      <li>
      <Link to="/casestudy/">Case Study</Link>   </li>
      <li>
      <Link to="/">Study Up </Link>   </li>


    </ul>
  </nav>
  <div class="sidebar">
  <ul class="a">


      <li class="d"><AnchorLink to="/studyguide#rsu"><b>Study Guide</b></AnchorLink>

<li class="d">
    <AnchorLink to="/studyguide#applied">Applied Examples</AnchorLink>
  </li>
  <br /><br />
  <li class="d">
      <AnchorLink to="/studyguide#speculative">Speculative Examples</AnchorLink>
    </li>
</li>
  <li class="d"><AnchorLink to="/studyguide#nav"><b>Navigating the Political Economy of Data Science</b></AnchorLink>
  <li class="d">
      <AnchorLink to="/studyguide#gaps">Filling the Gaps</AnchorLink>
    </li>
    <li class="d">
    <AnchorLink to="/studyguide#data"> Interpreting and Labeling Data </AnchorLink>
    </li>
    <li class="d">
    <AnchorLink to="/studyguide#refusal"> Refusal as a First Step </AnchorLink>
    </li>
    <li class="d">
    <AnchorLink to="/studyguide#challenging"> Challenges to Studying Up </AnchorLink>
    </li>
  </li>
  <li class="d">

  <AnchorLink to="/studyguide#other"> <b>Beyond Studying Up</b></AnchorLink>
  <br />
  <li class="d"> <AnchorLink to="/studyguide#role"> Role of Data Science </AnchorLink> </li>
  <li class="d"><AnchorLink to="/studyguide#liberty"> Liberatory Frameworks for Socio-technical Design </AnchorLink></li>

  </li>


  </ul>

</div>
<div class="content">
<div class="title" align="center" id="rsu">
<b>
 Study Guide
 </b>


<div align="center" class="subtitle"><i>(Are we missing something? Please let us know! <a href = "mailto:studyupalgo@gmail.com"> studyupalgo@gmail.com </a>)</i></div>
</div>
<div>
<div class="pSpacing">




</div>
<br />
<div id="applied" class="secondTitle"><p><b><u> Applied Examples</u></b></p></div>
<div class="pSpacing">
<p>Listed below are some practical examples of using data to "study up".</p>
<ul class="a">
<li>
<a href="https://tcjcdashboard.org/">Criminal Court Data Dashboard: </a>Takes millions of court dispositions from some of the largest counties in Texas, and makes them easy to access and understand. Developed by the Texas Criminal Justice Coalition.
</li>

<li>
<a href="https://antievictionmap.com/blog/2020/8/17/landlord-tech-watch">Anti-Eviction Mapping Project: </a> A resource and crowdsourcing tool to fight landlord technologies in our homes and neighborhoods.

</li>
<li>
<a href="https://turkopticon.ucsd.edu/">Turkopticon: </a>Helps the people in the 'crowd' of crowdsourcing watch out for each other—because nobody else seems to be.
<ol>
      <li class="d"><a href="https://dl.acm.org/doi/pdf/10.1145/2470654.2470742?casa_token=CdMms2zuty4AAAAA:4foeaCCYo4LEMx5SVz1_u0rHqFimRkTfYzNu6ew0HefmeqoXncLsFeF2D0QVVXMYllZuG5RaHqw"> Interrupting Worker Invisibility in Amazon Mechanical Turk </a></li>

    </ol>
</li>
<li>
<a href="https://www.courtwatchma.org/">Court Watch (MA, NYC): </a>Shifts the power dynamics in our courts by opening the courtroom to public scrutiny.

</li>
<li>
<a href="https://gitlab.com/jjwiseman/advisory-circular/">Advisory Circular: </a> A network of twitter bots that detect and tweet in real time whenever they detect police and military aircraft flying in circles.
<ol>
      <li class="d"><a href="https://twitter.com/SkyCirclesLA"> Los Angeles </a></li>
      <br />
      <li class="d"><a href="https://twitter.com/SkyCirclesSF">San Francisco Bay </a></li>
      <br />
      <li class="d"><a href="https://twitter.com/SkyCirclesDC">Washington D.C. </a></li>
      <br />
        <li class="d"><a href="https://twitter.com/SkyCirclesCHI">Chicago </a></li>

    </ol></li>

    <li class="d">
  <a href="https://mappingpoliceviolence.org/">Mapping Police Violence </a><br />


<br />
    </li>
    <br />
    <br />
    <br />
    <br />
<br />

</ul>

</div>
</div>



<div class="title" align="center" id="rsu">
<b>
<font color="white">.</font>

 </b>
</div>


<div align="left" class="secondTitle"><p><b><font color="white">
......</font></b></p></div>
<div>

<div  id="speculative" class="secondTitle"><p><b><u>
 Speculative Examples</u></b></p></div>
<div class="pSpacing">
Sometimes when we flip the script, it’s not to implement an applied solution to a problem, but to reveal the harmful or insistent or stigmatizing logics embedded in the technology.

</div>
</div>
<div class="pSpacing">
<ul class="a">

<li class="d">
<a href="https://whitecollar.thenewinquiry.com/">White Collar Crime Risk Zones  </a>

</li>

<br />
<li class="d">
<a href="https://www.aclu.org/blog/privacy-technology/surveillance-technologies/amazons-face-recognition-falsely-matched-28">ACLU’s Congressional Rekognition Project</a></li>
<br />
<li class="d">
<a href="https://www.youtube.com/watch?v=11e_rpdf-Ck">A Mulching Proposal: Analyzing and Improving and Algorithmic System for Turning the Elderly Into High-Nutrient Slurry
</a>
</li>

</ul>

</div>
<div class="title" align="center" id="">
<b>
<font color="white">.</font>
<font color="white">.</font>
<font color="white">.</font>
<br />
 </b>
</div><div class="title" align="center" id="">
<b>

 </b>
</div>
<div class="title"  align="center" id="nav">
<b>

Navigating the Political Economy of Data Science


 </b>
</div>
<div class="pSpacing">
Data work is profoundly shaped by a politics of access. When we “study up,” the conversation regarding our ethical engagement as data scientists and designers must expand to account for the specific challenges faced when holding the lens up to powerful people and institutions. Below are some resources and tactics that we found helpful in navigating the political economy of data science.

</div>
<div id="gaps" class="secondTitle"><p><b><u> Filling the Gaps </u></b></p></div>
<div class="pSpacing">
Sometimes we have to build infrastructure to fill the gaps where data has not been collected.
<br /> <br />
<ul class="a">

<li class="d">
Mimi Onuoha, <a href="https://github.com/MimiOnuoha/missing-datasets">On Missing Datasets</a>

</li>

<br />
<li class="d">
Currie, Morgan, et al. "<a href="https://journals.sagepub.com/doi/pdf/10.1177/2053951716663566">The conundrum of police officer-involved homicides: Counter-data in Los Angeles County.</a>" Big Data & Society 3.2 (2016): 2053951716663566.
</li>
<br />
<li class="d">
Irani, Lilly, and M. Six Silberman. "<a href="https://dl.acm.org/doi/fullHtml/10.1145/2627392?casa_token=d2Aq1z_W4e4AAAAA:qNk7RN-HkXt0IbBVU92ZGUqmv_mLt-os6Xh9FZv6lvsSLzQ0eKOxYRwNr9QgU7fBn7Z9DisauC-3">From critical design to critical infrastructure: lessons from turkopticon.</a>"Interactions 21.4 (2014): 32-35.
</li>

</ul>
<font color="white">.</font>
</div>
<div id="data" class="secondTitle"><p><b><u> Interpreting and Labeling Data </u></b></p></div>
<div class="pSpacing">How might existing data be re-interpreted and re-purposed to support accountability for those with the most power and agency in a given system?
<br />
<br />
<ul class="a">

<li class="d">
Dourish, Paul, and Edgar Gómez Cruz. "<a href="https://journals.sagepub.com/doi/pdf/10.1177/2053951718784083">Datafication and data fiction: Narrating data and narrating with data.</a>" Big Data & Society 5.2 (2018): 2053951718784083.

</li>

<br />
<li class="d">
Benjamin, Ruha. "<a href="https://science.sciencemag.org/content/366/6464/421.full?ijkey=jV1o%2FNMCG.a7g&keytype=ref&siteid=sci">Assessing risk, automating racism.</a>" Science 366.6464 (2019): 421-422.
<li class="d">
<p> “It is vital to develop tools that move from assessing individual risk to evaluating the production of risk by institutions so that, ultimately, the public can hold them accountable for harmful outcomes.”
</p></li>
</li>



</ul>

<font color="white">.</font>

<font color="white">.</font>
</div>

<div id="refusal" class="secondTitle"><p><b><u> Refusal as a First Step </u></b></p></div>
<div class="pSpacing">How might we engage in the art of refusal to fundamentally re-imagine the terms of our engagement with the gatekeepers of data?

<br />
<br />
<ul class="a">
<li class="d">
Chelsea Barabas. 2020. <a href="https://onezero.medium.com/refusal-a-beginning-that-starts-with-an-end-2b055bfc14be"> "To Build a Better Future, Designers Need to Start Saying 'No'"</a>
</li>
<br />
<li class="d">
 <a href="https://www.manifestno.com/">Feminist Data Manifest-no</a>

</li>

<br />
<li class="d">
Graeff, E. 2020. "<a href="https://pdc2020cpsr.pubpub.org/pub/vizamy14/release/1">The Responsibility to Not Design and the Need for Citizen Professionalism.</a>" Computing Professionals for Social Responsibility: The Past, Present and Future Values of Participatory Design.


</li>
<li class="d">
Tuck, Eve, and K. Wayne Yang. "<a href="https://journals.sagepub.com/doi/pdf/10.1177/1077800414530265?casa_token=dBbJ0vssPcoAAAAA:lqwx8AEYrfW7HKCW0wY1_f3fggjnmwZYwA1Pyo9Zs-q5BIaPrLFkeRAGKYo4OL1zDzQdOYG5CSVH">Unbecoming claims: Pedagogies of refusal in qualitative research.</a>" Qualitative Inquiry 20.6 (2014): 811-818.


</li>



</ul>
</div>
<ul>

<br /> <br /><br /><br /><font color="white">.</font></ul>

<font color="white">.</font>
<br />


<br />

<br />

<div id="challenging" class="secondTitle"><p><b><u>Challenges to Studying Up: Lessons from Anthropology </u></b></p></div>

<div class="pSpacing">We can learn a lot from anthropologists’ prior attempts to study up.

<br />
<br />
<ul class="a">

<li class="d">
 Hugh Gusterson. 1997. <a href="https://heinonline.org/HOL/LandingPage?handle=hein.journals/polar20&div=15&id=&page=">Studying up revisited.</a>
 PoLAR: Political and Legal Anthropology Review 20, 1 (1997), 114–119.
</li>

<br />
<li class="d">
Daniel Souleles. 2018. <a href="https://anthrosource.onlinelibrary.wiley.com/doi/pdf/10.1111/plar.12253?casa_token=NgweLAZfYXQAAAAA:Hb2bTtVfVo7kJrIvuu8DIdGLgi8pshuN03EernfBebGssUuB4jDiOv5dW8vxX-rbeKrzpDwar28Idus">How to Study People Who Do Not Want to be Studied: Practical Reflections on Studying Up.</a>" Computing Professionals for Social Responsibility: The Past, Present and Future Values of Participatory Design.

PoLAR: Political and Legal Anthropology Review 41, S1 (2018), 51–68.

</li>

</ul>
</div>
<font color="white">.</font>





<br />
<br />
<br />
<br />
<br />
<div align="center" id="other" class="title"><p><b>Beyond Studying Up: Other Useful Concepts </b></p></div>
<br />
<div id="role"class="secondTitle"><b><u>The role of data science in reproducing oppression</u></b></div>
<div class="pSpacing">
The call to “study up” is based on the observation that data scientists often reproduce social inequities by focusing the algorithmic gaze exclusively on the poor and marginalized. We are doomed to reinforce the status quo when our “solutions” to social problems turn a blind eye to the powerful.
<br /><br />
For more on the role of technology in reproducing and naturalizing social inequities, check out these key concepts:


<br />
<br />
<ul class="a">

<li class="d">
 Benjamin’s (2019)<a href="https://www.ruhabenjamin.com/race-after-technology">“New Jim Code”  </a>
</li>


<br />
<li class="d">
Browne's (2015) <a href="https://www.dukeupress.edu/dark-matters">“racializing surveillance”</a> and (2010) <a href="https://www.semanticscholar.org/paper/Digital-Epidermalization%3A-Race%2C-Identity-and-Browne/b21bdfd46a5c7ab3c6b71401762ed986bb0571c3?p2df">"digital epidermalization"</a>
</li>
<font color="white">.</font>
<li class="d">
Broussard’s (2018)<a href="https://mitpress.mit.edu/books/artificial-unintelligence"> “technochauvinism” </a>
</li>
<br />
<br />
<li class="d">
Buolamwini’s (2016)<a href="https://www.youtube.com/watch?v=162VzSzzoPs"> "coded gaze"</a>

</li>
<br />
<li class="d">
Eubanks’ (2018) <a href="https://us.macmillan.com/books/9781250074317"> "digital poorhouse" </a>
</li>
<br />


<li class="d">
Noble’s (2018)  <a href="https://nyupress.org/9781479837243/algorithms-of-oppression/"> "technological redlining" </a>
</li>
</ul>
</div>
<font color="white">.</font>


<div id="liberty"class="secondTitle"><b><u>Liberatory Frameworks for Socio-technical Design</u></b></div>
<br />
<div class="pSpacing">
<p>"Studying up” is just one of many useful frameworks for grappling with power, the algorithmic gaze and struggles for justice. Here are some other resources we've found useful for developing critical and generative approaches to  data science." </p>
<br />
<a id="challenges" href="https://static1.squarespace.com/static/5a92f54baf209610a9b49b30/t/5c4a29babba2234e2a135e40/1548364219323/Challenges+and+Pitfalls+of+Reforms+-+Oct+29+2018+-+11-45+AM.pdf"><u>Challenges and Pitfalls of Reforms</u></a>
<br />
This document was compiled by Mariame Kaba and provides a set of questions we can use to reflect on whether a proposed reform or intervention reproduces harmful systems of oppression or makes way for liberatory possibilities to emerge.
<br />
<br />
<a id="abolition" href="https://www.wiley.com/en-us/Race+After+Technology:+Abolitionist+Tools+for+the+New+Jim+Code-p-9781509526437"><u>Abolitionist Tools for the New Jim Code</u></a>
<br />

“An abolitionist toolkit... is concerned not only with emerging technologies but also with the everyday production, deployment, and interpretation of data. Such toolkits can be focused on computational interventions, but they do not have to be. In fact, narrative tools are essential.”
<li class="d"> Benjamin, Ruha.<i> Race after technology: Abolitionist tools for the new jim code. </i>John Wiley & Sons, 2019.
</li>


<div>
<br />

</div>

<br />

<br />

<a id="postcolonial" href="Post"><u>Postcolonial Computing</u> </a>

<br />“Postcolonial Computing is a bag of tools that affords us contingent tactics for continual, careful, collective, and always partial reinscriptions of a cultural–technical situation in which we all find ourselves.”
<li class="d">Philip, Kavita, Lilly Irani, and Paul Dourish. "Postcolonial computing: A tactical survey." Science, Technology, & Human Values 37.1 (2012): 3-29.
</li>
<br />
<br />



</div>

<br />
<br />

</div>

  </Layout>
)

export default SecondPage
